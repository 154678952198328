import 'cleave.js/dist/addons/cleave-phone.au.js';
import * as Sentry from '@sentry/react';
import * as yup from 'yup';
import config from '~/core/shared/providers/config';
import GoogleTagManager from 'react-gtm-module';
import { TenantConfig } from '~/core/declarations';
import { isEmpty, isNull, isUndefined } from 'lodash-es';

function init(tenantConfig: TenantConfig): void {
  config.setTenantConfig(tenantConfig);

  const { gtmId, nodeEnv, sentryDsn } = config;

  //Initialize Sentry
  if (!isUndefined(sentryDsn) || !isNull(sentryDsn) || !isEmpty(sentryDsn)) {
    Sentry.init({
      environment: config.nodeEnv,
      dsn: sentryDsn,
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: nodeEnv === 'production' ? 0.25 : 1.0,
      beforeSend(event, hint) {
        const exception = hint?.originalException;
        
        if (exception instanceof Error && exception.message === 'Failed to get document transitions') {
          return null; // Exclude this event from being sent to Sentry
        }

        return event;
      },
    });
    Sentry.setTag('tenant', config.tenant);
  }


if (nodeEnv === 'production') {
    // Initialize GTM
    if (gtmId) {
      GoogleTagManager.initialize({ gtmId });
    }
}

  // Configure yup
  yup.setLocale({
    mixed: {
      oneOf: 'This is a required field',
      required: 'This is a required field',
    },
    string: {
      email: 'This field must be a valid email',
      length: 'This field must have exactly ${length} characters',
      max: 'This field cannot exceed ${max} characters',
      min: 'This field must have a minimum of ${min} characters',
    },
  });
  
}

export default init;